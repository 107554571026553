import {Injectable} from '@angular/core';
import {SeoModel} from './models/seo.model';
import {SharedService} from './services/shared.service';

@Injectable({
    providedIn: 'root'
})
export class SharedFacade {

    constructor(private _sharedService: SharedService) {}

    public updateMetaTags(seo?: SeoModel, lang?: string): void {
        this._sharedService.updateMetaTags(seo, lang);
    }
}
