export class SeoModel {
    public seo_title_it?: string;
    public seo_title_en?: string;
    public seo_description_it?: string;
    public seo_description_en?: string;
    public seo_tags?: string[] = [];
    public uri?: string;

    constructor(seo_title_it?: string, seo_title_en?: string, seo_description_it?: string, seo_description_en?: string, seo_tags?: string[], uri?: string) {
        this.seo_title_it = seo_title_it;
        this.seo_title_en = seo_title_en;
        this.seo_description_it = seo_description_it;
        this.seo_description_en = seo_description_en;
        this.seo_tags = seo_tags;
        this.uri = uri;
    }

    public static buildTryForOneEuroPage(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'Biglietti, Volantini, Locandine ad 1 euro! - PressUP';
        seoModel.seo_title_en = 'Tickets, Flyers, Posters for 1 euro! - PressUP';
        seoModel.seo_description_it = 'PressUP offre ai nuovi clienti con Partita IVA per solo 1 Euro: 250 biglietti da visita, 100 Volantini A5, 50 locandine A4 o 250 inviti 10x15!';
        seoModel.seo_description_en = 'PressUP offers new customers with VAT number for only 1 Euro: 250 business cards, 100 A5 flyers, 50 A4 posters or 250 10x15 invitations!';
        return seoModel;
    }

    public static builderHomeSeoModel(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'Stampa Digitale Online: personalizzazione e consegna rapida - PressUP';
        seoModel.seo_title_en = 'Online Digital Printing: customization and fast delivery - PressUP';
        seoModel.seo_description_it = 'Stampa Digitale Online, offset e grande formato. Volantini, pieghevoli, depliant, brochure, manifesti, riviste, cataloghi, cartoline, etichette, espositori.';
        seoModel.seo_description_en = 'PressUP offers new customers with VAT number for only 1 Euro: 250 business cards, 100 A5 flyers, 50 A4 posters or 250 10x15 invitations!';
        seoModel.seo_tags = ['biglietti da visita', 'biglietti auguri', 'volantini offerte', 'stampa digitale', 'stampa biglietti da visita', 'stampa offset', 'libri', 'cataloghi', 'brochure', 'depliant', 'pieghevoli', 'grande formato', 'buste', 'poster', 'locandine', 'manifesti']
        return seoModel;
    }

    public static builderCashback(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'Programma CashBack - PressUP';
        seoModel.seo_title_en = 'CashBack Program - PressUP';
        seoModel.seo_description_it = 'Scopri il programma CashBack di PressUP. Ogni mese ti restutuiamo una parte di quello che hai speso. ';
        seoModel.seo_description_en = 'Discover the PressUP CashBack program. Every month we give you back a portion of what you spent.';
        seoModel.seo_tags = ['biglietti da visita', 'biglietti auguri', 'volantini offerte', 'stampa digitale', 'stampa biglietti da visita', 'stampa offset', 'libri', 'cataloghi', 'brochure', 'depliant', 'pieghevoli', 'grande formato', 'buste', 'poster', 'locandine', 'manifesti']
        return seoModel;
    }

    public static builderPageNotFoundSeoStructureModel(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'PressUP - 404';
        seoStructure.seo_title_en = 'PressUP - Pagina non trovata';
        seoStructure.seo_description_it = 'PressUP - Pagina non trovata';
        seoStructure.seo_description_en = 'PressUP - Page not found';
        return seoStructure;
    }

    public static builderDefaultPage(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'PressUP';
        seoStructure.seo_title_en = 'PressUP';
        seoStructure.seo_description_it = 'PressUP - Stampa Digitale Online, offset e grande formato. Volantini, pieghevoli, depliant, brochure, manifesti, riviste, cataloghi, cartoline, etichette, espositori.\n';
        seoStructure.seo_description_en = 'PressUP - Online Digital Printing, offset and large format. Flyers, leaflets, leaflets, brochures, posters, magazines, catalogs, postcards, labels, displays.';
        return seoStructure;
    }

    public static buildPrintingTechniques(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'PressUP - Tecniche di stampa';
        seoStructure.seo_title_en = 'PressUP - Printing techniques';
        seoStructure.seo_description_it = 'Facciamo Stampa online con la nostra tipografia digitale a Roma. Stampa offset e digitale personalizzata, consegna in 24h, prezzi economici.';
        seoStructure.seo_description_en = 'We do online printing with our digital printing in Rome. Offset and digital printing customized, delivery in 24h, cheap prices.';
        return seoStructure;
    }

    public static buildDesignYourIdea(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'Progettiamo le tue idee - PressUP';
        seoStructure.seo_title_en = 'Design your idea - PressUP';
        seoStructure.seo_description_it = 'Raccontaci la tua idea e noi ti aiutiamo a realizzarla';
        seoStructure.seo_description_en = 'Tell us your idea and we will help you make it happen';
        return seoStructure;
    }

    public static buildGuidelines(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'Linee Guida - PressUP';
        seoStructure.seo_title_en = 'Guidelines - PressUP';
        seoStructure.seo_description_it = 'Come preparare i file grafici';
        seoStructure.seo_description_it = 'How to prepare graphic files';
        return seoStructure;
    }

    public static buildSustainablePrinting(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'Stampa Sostenibile - PressUP';
        seoStructure.seo_title_en = 'Sustainable Printing - PressUP';
        seoStructure.seo_description_it = 'CERTIFICAZIONE FSC - CERTIFICAZIONE PEFC - TWO SIZES - NATURALMENTE IO LA CARTA';
        seoStructure.seo_description_en = 'FSC CERTIFICATION - PEFC CERTIFICATION - TWO SIZES - NATURALMENTE ME LA PAPER';
        seoStructure.seo_tags = ['CERTIFICAZIONE FSC', 'CERTIFICAZIONE PEFC', 'TWO SIZES', 'NATURALMENTE IO LA CARTA'];
        return seoStructure;
    }

    public static buildFaq(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'Faq - PressUP';
        seoStructure.seo_title_en = 'Faq - PressUP';
        seoStructure.seo_description_it = 'Le Faq di PressUP';
        seoStructure.seo_description_en = 'Faq PressUP';
        seoStructure.seo_tags = ['Faq', 'FAQ', 'Domande', 'Le Faq di PressUP'];
        return seoStructure;
    }

    public static buildCookie(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'Cookie Policy - PressUP';
        seoStructure.seo_title_en = 'Cookie Policy - PressUP';
        seoStructure.seo_description_it = 'PressUp utilizza cookie per fornire servizi che migliorino l’esperienza di navigazione\n' +
            'degli utenti. ';
        seoStructure.seo_description_en = 'PressUp use cookie to offer services for increase user navigation experience of users';
        seoStructure.seo_tags = ['Cookie', 'Policy', 'User Experience'];
        return seoStructure;
    }

    public static buildPrivacy(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'Privacy - PressUP';
        seoStructure.seo_title_en = 'Privacy - PressUP';
        seoStructure.seo_description_it = 'Condizioni generali di contratto - informativa sul trattamento dei dati personali';
        seoStructure.seo_description_en = 'General Terms and Conditions - Information on the Processing of Personal Data';
        seoStructure.seo_tags = ['Privacy', 'Condizioni generali', 'contratto'];
        return seoStructure;
    }

    public static buildGeneralCondition(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'Press Up - Condizioni generali di contratto';
        seoStructure.seo_title_en = 'Press Up - General contract conditions';
        seoStructure.seo_description_it = 'Termini e condizioni generali di uso e di vendita';
        seoStructure.seo_description_en = 'General terms and conditions of use and sale';
        seoStructure.seo_tags = ['Termini', 'Contratto', 'Premesse', 'Oggetto del Contratto on line', ''];
        return seoStructure;
    }

    public static buildBlog(): SeoModel {
        const seoStructure = new SeoModel();
        seoStructure.seo_title_it = 'Il blog di PressUP — Notizie dal mondo del Web To Print. Tutto quello che volevi sapere sulla stampa e non hai mai osato chiedere';
        seoStructure.seo_title_en = 'PressUP Blog — News from the Web To Print world. Everything you wanted to know about printing and were afraid to ask';
        seoStructure.seo_description_it = 'Notizie dal mondo del Web To Print. Tutto quello che volevi sapere sulla stampa e non hai mai osato chiedere';
        seoStructure.seo_description_en = 'News from the world of Web To Print. Everything you wanted to know about printing and never dared to ask';
        seoStructure.seo_tags = ['Il blog di PressUP'];
        return seoStructure;
    }

    public static buildAccountDataPage(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'Dati Account - PressUP';
        seoModel.seo_title_en = 'Account Data - PressUP';
        seoModel.seo_description_it = 'Gestisci i tuoi dati personali e aggiorna le informazioni del tuo account su PressUP.';
        seoModel.seo_description_en = 'Manage your personal data and update your account information on PressUP.';
        return seoModel;
    }

    public static buildShippingAddressesPage(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'Indirizzi di Spedizione - PressUP';
        seoModel.seo_title_en = 'Shipping Addresses - PressUP';
        seoModel.seo_description_it = 'Visualizza e modifica i tuoi indirizzi di spedizione su PressUP.';
        seoModel.seo_description_en = 'View and edit your shipping addresses on PressUP.';
        return seoModel;
    }

    public static buildOrdersPage(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'Ordini - PressUP';
        seoModel.seo_title_en = 'Orders - PressUP';
        seoModel.seo_description_it = 'Consulta lo stato dei tuoi ordini effettuati su PressUP.';
        seoModel.seo_description_en = 'Check the status of your orders placed on PressUP.';
        return seoModel;
    }

    public static buildArchivedOrdersPage(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'Ordini Archiviati - PressUP';
        seoModel.seo_title_en = 'Archived Orders - PressUP';
        seoModel.seo_description_it = 'Consulta lo storico degli ordini archiviati su PressUP.';
        seoModel.seo_description_en = 'View the history of your archived orders on PressUP.';
        return seoModel;
    }

    public static buildCashbackPage(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'Buoni Sconto - PressUP';
        seoModel.seo_title_en = 'Discount Vouchers - PressUP';
        seoModel.seo_description_it = 'Gestisci i tuoi buoni sconto disponibili su PressUP.';
        seoModel.seo_description_en = 'Manage your available discount vouchers on PressUP.';
        return seoModel;
    }

    public static buildNotificationManagementPage(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'Gestione Notifiche - PressUP';
        seoModel.seo_title_en = 'Notification Management - PressUP';
        seoModel.seo_description_it = 'Configura le preferenze di notifica per ricevere aggiornamenti su PressUP.';
        seoModel.seo_description_en = 'Set up notification preferences to receive updates on PressUP.';
        return seoModel;
    }

    public static buildAssistancePage(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'Assistenza - PressUP';
        seoModel.seo_title_en = 'Assistance - PressUP';
        seoModel.seo_description_it = 'Trova supporto e risolvi problemi con l’assistenza clienti di PressUP.';
        seoModel.seo_description_en = 'Find support and resolve issues with PressUP customer service.';
        return seoModel;
    }

    public static buildCart(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'PressUP - Stampare online - Carrello';
        seoModel.seo_title_en = 'PressUP - Online print - Cart';
        seoModel.seo_description_it = 'PressUP - Stampare online - Carrello';
        seoModel.seo_description_en = 'PressUP - Online print - Cart';
        return seoModel;
    }

    public static buildLogin(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'PressUP - Stampare online - Accedi';
        seoModel.seo_title_en = 'PressUP - Online print - Login';
        seoModel.seo_description_it = 'Accedi su PressUP per consultare la tua area riservata.';
        seoModel.seo_description_en = 'Log in to PressUP to view your reserved area.';
        return seoModel;
    }

    public static buildRegister(): SeoModel {
        const seoModel = new SeoModel();
        seoModel.seo_title_it = 'PressUP - Stampare online - Registrazione';
        seoModel.seo_title_en = 'PressUP - Online print - Registration';
        seoModel.seo_description_it = 'Registrazione su PressUP per consultare la tua area riservata';
        seoModel.seo_description_en = 'Register on PressUP to consult your reserved area';
        return seoModel;
    }
}
