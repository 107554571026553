import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpContext, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ItemStructure} from '../models/item-structure.model';
import {AttributesTechnicalProduct} from '../models/attributes-technical-product.model';
import {EstimateCost} from '../models/estimate-cost.model';
import {Price} from '../models/price.model';
import {EyeletesRequest} from '../models/eyeletes-request.model';
import {ResponseModel} from '../models/response-model.model';
import {RequestCartItem} from '../models/request-cart-item.model';
import {GenericResponse} from '../../../modules/auth/models/generic.response';
import {CartItemShipmentRequest} from '../models/cart-item-shipment-request.model';
import {PaymentMethod} from '../models/payment-method.model';
import {IS_STRAPI_API} from '../../../core/enums/public-api.token';
import {EstimateCostResponse} from '../models/estimate-cost-response.model';
import {Order} from "../../../modules/profile/models/orders-models";

@Injectable({
    providedIn: 'root'
})
export class DynamicLoaderService {

    private readonly ENDPOINT_V2: string = `${environment.addressPressupBackofficeAPI}${environment.api_v2}`;
    private readonly ENDPOINT_PRESSUP: string = `${environment.addressPressupAPIDEM}${environment.api_v1}`;

    constructor(private http: HttpClient) {
    }

    public getItemStructureById(id: string): Observable<ItemStructure> {
        return this.http.get<ItemStructure>(`${this.ENDPOINT_V2}/menu-item/${id}`);
    }

    public getGenericItemByUri(uri: string): Observable<ItemStructure> {
        return this.http.get<ItemStructure>(`${this.ENDPOINT_V2}/menu-item/uri?uri=${uri}`);
    }

    public getTechnicalProducts(technicalProductKey: string): Observable<AttributesTechnicalProduct> {
        return this.http.get<AttributesTechnicalProduct>(`${this.ENDPOINT_PRESSUP}/Product/Get?key=${technicalProductKey}`);
    }

    public sendNewQuote(quote: any): Observable<EstimateCostResponse> {
        return this.http.post<EstimateCostResponse>(`${this.ENDPOINT_PRESSUP}/Product/Quote`, quote);
    }

    public getAllTechnicalProducts(): Observable<Array<AttributesTechnicalProduct>> {
        return this.http.get<Array<AttributesTechnicalProduct>>(`${this.ENDPOINT_PRESSUP}/Product/GetAll`,
            {
                context: new HttpContext().set(IS_STRAPI_API, true),
            });
    }

    public getGetEyeletsTechnicalProducts(eyelets: EyeletesRequest): Observable<ResponseModel> {
        return this.http.get<ResponseModel>(`${this.ENDPOINT_PRESSUP}/Product/GetEyelets/${eyelets?.accessor}/${eyelets?.verticalFormat}/${eyelets?.horizontalFormat}/?_FormatoOriz=${eyelets?.horizontalFormat}`);
    }

    public getPaymentMethod(): Observable<Array<PaymentMethod>> {
        return this.http.get<Array<PaymentMethod>>(`${this.ENDPOINT_PRESSUP}/ShoppingCart/GetPaymentMethod`);
    }

    public estimateCost(productEstimateCost: EstimateCost): Observable<Price> {
        return this.http.post<Price>(`${this.ENDPOINT_PRESSUP}/Product/EstimateCost`, productEstimateCost);
    }

    public placeOrder(paymentMethod?: number, requestBody?: unknown): Observable<Order> {
        return this.http.put<Order>(`${this.ENDPOINT_PRESSUP}/ShoppingCart/PlaceOrder?paymentMethod=${paymentMethod}`, requestBody);
    }

    public getRelatedProduct(terms: { terms: string[]; }): Observable<Array<ItemStructure>> {
        return this.http.post<Array<ItemStructure>>(`${this.ENDPOINT_V2}/product/related-products-by-criteria`, terms);
    }

    public setCartItem(customerEmail?: string, cartItem?: RequestCartItem): Observable<GenericResponse> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.http.put<GenericResponse>(
            `${this.ENDPOINT_PRESSUP}/ShoppingCart/SetCartItem?guid=${customerEmail}`,
            cartItem,
            {headers}
        );
    }

    public addShipment(id?: number, cartItemShipment?: CartItemShipmentRequest): Observable<GenericResponse> {
        return this.http.patch<GenericResponse>(`${this.ENDPOINT_PRESSUP}/ShoppingCart/AddShipment?cartitemid=${id}`, cartItemShipment);
    }

    public deleteShipment(id?: number, shipmentNumber?: number): Observable<GenericResponse> {
        return this.http.delete<GenericResponse>(`${this.ENDPOINT_PRESSUP}/ShoppingCart/DeleteShipment?cartitemid=${id}&shipmentnumber=${shipmentNumber}`);
    }

    public removeCartItem(id?: number, customerEmail?: string): Observable<GenericResponse> {
        return this.http.delete<GenericResponse>(`${this.ENDPOINT_PRESSUP}/ShoppingCart/RemoveCartItem?id=${id}&guid=${customerEmail}`);
    }
}
