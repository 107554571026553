import {TranslateService} from '@ngx-translate/core';

export class ModelField {
    public icon: string;
    public text: string;
    public text_btn: string;
    public sub_text: string;

    constructor(icon?: string, text?: string, text_btn?: string, sub_text?: string) {
        this.icon = icon;
        this.text = text;
        this.text_btn = text_btn;
        this.sub_text = sub_text;
    }

    public static buildBadRequest(icon?: string, text?: string, text_btn?: string, sub_text?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_segnalazione.svg';
        const txt = text ?? 'MODAL.WRONG_CREDENTIAL';
        const txt_btn = text_btn ?? 'Ok';
        const sub_txt = sub_text ?? 'MODAL.INCORRECT_CREDENTIALS_TEXT';
        return new ModelField(icn, txt, txt_btn, sub_txt);
    }

    public static buildInternalServerError(icon?: string, text?: string, text_btn?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_attenzione.svg';
        const txt = text ?? 'MODAL.COMMUNICATION_ERROR';
        const txt_btn = text_btn ?? 'Ok';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildAlreadyExistError(icon?: string, text?: string, text_btn?: string, sub_text?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_segnalazione.svg';
        const txt = text ?? 'MODAL.CREDENTIALS_ALREADY_USED';
        const txt_btn = text_btn ?? 'Ok';
        const sub_txt = sub_text ?? 'MODAL.INCORRECT_CREDENTIALS_TEXT';
        return new ModelField(icn, txt, txt_btn, sub_txt);
    }

    public static buildGenericSuccess(icon?: string, text?: string, text_btn?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_conferma.svg';
        const txt = text ?? 'TOAST.OPERATION_SUCCESS';
        const txt_btn = text_btn ?? 'Ok';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildGoToLogin(icon?: string, text?: string, text_btn?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_conferma.svg';
        const txt = text ?? 'MODAL.VALIDATION_SUCCESS';
        const txt_btn = text_btn ?? 'MODAL.GO_TO_LOGIN_FROM_VALIDATION';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildPasswordUpdatedSuccess(icon?: string, text?: string, text_btn?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_conferma.svg';
        const txt = text ?? 'MODAL.PASSWORD_UPDATED';
        const txt_btn = text_btn ?? 'MODAL.GO_TO_LOGIN_FROM_VALIDATION';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildTokenExpired(icon?: string, text?: string, text_btn?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_attenzione.svg';
        const txt = text ?? 'MODAL.TOKEN_EXPIRED';
        const txt_btn = text_btn ?? 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildNotificationAddedSuccess(icon?: string, text?: string, text_btn?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_conferma.svg';
        const txt = text ?? 'MODAL.ADD_NOTIFICATION_SUCCESS';
        const txt_btn = text_btn ?? 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn);
    }

    public static genericBuildDeletingItem(icon?: string, text?: string, text_btn?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_attenzione.svg';
        const txt = text ?? 'MODAL.ERROR_DELETING_ITEM';
        const txt_btn = text_btn ?? 'GENERAL.RETRY';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildAmountStepOneSubject(amount?: number, translate?: TranslateService): ModelField {
        const icn = 'assets/images/press-up/icone-cerchio/icn_modale_conferma.svg';
        const txt = `${translate.instant('MODAL.TOTAL_AMOUNT_MULTI_SUBJECT')} ${amount.toLocaleString('en-US', {minimumFractionDigits: 2})} € + iva`;
        const txt_btn = 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildUploadFileSuccess(icon?: string, text?: string, text_btn?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_conferma.svg';
        const txt = text ?? 'MODAL.UPLOAD_FILE_SUCCESS';
        const txt_btn = text_btn ?? 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildUploadFileErrors(icon?: string, text?: string, text_btn?: string): ModelField {
        const icn = icon ?? 'assets/images/press-up/icone-cerchio/icn_modale_attenzione.svg';
        const txt = text ?? 'MODAL.UPLOAD_FILE_ERROR';
        const txt_btn = text_btn ?? 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildAssistanceStepOne(): ModelField {
        const icn = 'assets/images/press-up/icone-cerchio/icn_modale_attenzione.svg';
        const txt = 'MODAL.ORDER_ITEM_ID_ERROR';
        const txt_btn = 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildCreateNote(): ModelField {
        const icn = 'assets/images/press-up/icone-cerchio/cerchio_note.svg';
        const txt = 'CART.ADD_NOTES';
        const sub_txt = 'CART.MODAL_SUBTITLE';
        const txt_btn = 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn, sub_txt);
    }

    public static buildCreateChooseShippingType(type: string): ModelField {
        const icn = 'assets/images/press-up/icone-cerchio/cerchio_rinomina.svg';
        const txt = type;
        const txt_btn = 'GENERAL.CONFIRM';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildErrorSendMail(): ModelField {
        const icn = 'assets/images/press-up/icone-cerchio/icn_modale_attenzione.svg';
        const txt = 'MODAL.SEND_MAIL_ERROR';
        const txt_btn = 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildErrorOnCountCopies(): ModelField {
        const icn = 'assets/images/press-up/icone-cerchio/bollino_copie_non_valide.svg';
        const txt = 'MODAL.NUMBER_ERROR';
        const txt_btn = 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildPaymentDone(): ModelField {
        const icn = 'assets/images/press-up/icone-cerchio/icn_modale_conferma.svg';
        const txt = 'MODAL.PAYMENT_DONE';
        const txt_btn = 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn);
    }

    public static buildPaymentError(): ModelField {
        const icn = 'assets/images/press-up/icone-cerchio/icn_modale_attenzione.svg';
        const txt = 'MODAL.PAYMENT_ERROR';
        const sub_txt = 'MODAL.RETRY';
        const txt_btn = 'GENERAL.OK';
        return new ModelField(icn, txt, txt_btn, sub_txt);
    }
}
