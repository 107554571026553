import {createReducer, on} from '@ngrx/store';
import {cartAction} from '../action/cart.action';
import {CartItemsModel} from '../../../../shop/cart/models/cart-items.model';

export const cartReducer = createReducer(
    {},
    on(cartAction.setCart, (state: CartItemsModel, {data}) => ({
        ...state,
        data
    })),
    on(cartAction.resetCart, (_state: CartItemsModel, {}) => ({}))
);
