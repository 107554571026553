import {SeoModel} from '../models/seo.model';

export class SeoCodePageSingleton {
    private static instance: SeoCodePageSingleton;
    private _seoCodePageSingleton: Map<string, SeoModel>;

    private constructor() {
        this.setSeoCodePageSingleton();
    }

    public static getInstance(): SeoCodePageSingleton {
        if (!SeoCodePageSingleton.instance) {
            SeoCodePageSingleton.instance = new SeoCodePageSingleton();
        }
        return SeoCodePageSingleton.instance;
    }

    public getSeoCodePageSingleton(principalCodeApiDepartmentGeneralDetailPage: string): SeoModel {
        return this._seoCodePageSingleton.get(principalCodeApiDepartmentGeneralDetailPage);
    }

    private setSeoCodePageSingleton(): void {
        this._seoCodePageSingleton = new Map<string, SeoModel>();
        this._seoCodePageSingleton.set(
            '/try-it-for-a-euro',
            SeoModel.buildTryForOneEuroPage()
        );
        this._seoCodePageSingleton.set(
            '/404',
            SeoModel.builderPageNotFoundSeoStructureModel()
        );
        this._seoCodePageSingleton.set(
            '/',
            SeoModel.builderHomeSeoModel()
        );
        this._seoCodePageSingleton.set(
            '/home',
            SeoModel.builderHomeSeoModel()
        );
        this._seoCodePageSingleton.set(
            '/design-your-idea',
            SeoModel.buildDesignYourIdea()
        );
        this._seoCodePageSingleton.set(
            '/printing-techniques',
            SeoModel.buildPrintingTechniques()
        );
        this._seoCodePageSingleton.set(
            '/guidelines',
            SeoModel.buildGuidelines()
        );
        this._seoCodePageSingleton.set(
            '/sustainable-printing',
            SeoModel.buildSustainablePrinting()
        );
        this._seoCodePageSingleton.set(
            '/cashback-program',
            SeoModel.builderCashback()
        );
        this._seoCodePageSingleton.set(
            '/faq',
            SeoModel.buildFaq()
        );
        this._seoCodePageSingleton.set(
            '/cookie-policy',
            SeoModel.buildCookie()
        );
        this._seoCodePageSingleton.set(
            '/privacy',
            SeoModel.buildPrivacy()
        );
        this._seoCodePageSingleton.set(
            '/general_conditions_of_sale',
            SeoModel.buildGeneralCondition()
        );
        this._seoCodePageSingleton.set(
            '/blog',
            SeoModel.buildBlog()
        );
        this._seoCodePageSingleton.set(
            '/profile/account-data',
            SeoModel.buildAccountDataPage()
        );
        this._seoCodePageSingleton.set(
            '/profile/shipping-addresses',
            SeoModel.buildShippingAddressesPage()
        );
        this._seoCodePageSingleton.set(
            '/profile/orders',
            SeoModel.buildOrdersPage()
        );
        this._seoCodePageSingleton.set(
            '/profile/archived-orders',
            SeoModel.buildArchivedOrdersPage()
        );
        this._seoCodePageSingleton.set(
            '/profile/cashback',
            SeoModel.buildCashbackPage()
        );
        this._seoCodePageSingleton.set(
            '/profile/notification-management',
            SeoModel.buildNotificationManagementPage()
        );
        this._seoCodePageSingleton.set(
            '/profile/assistance',
            SeoModel.buildAssistancePage()
        );
        this._seoCodePageSingleton.set(
            '/shop/cart',
            SeoModel.buildCart()
        );
        this._seoCodePageSingleton.set(
            '/auth/login',
            SeoModel.buildLogin()
        );
        this._seoCodePageSingleton.set(
            '/auth/registration',
            SeoModel.buildRegister()
        );
    }
}
