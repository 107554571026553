<p-toast/>
<div class="icon-nav">
    <ul class="d-flex align-items-center">
        <li class="show-div setting mobile-profile">
            <app-icon-profile-user></app-icon-profile-user>
        </li>
        <li class="onhover-div mobile-lang">
            <div>
                <app-language-selector></app-language-selector>
            </div>
        </li>
        <li class="onhover-div mobile-cart">
            <div>
                <div [routerLink]="['/shop/cart']" class="d-flex">
                    <img src="assets/images/icon/menu/icn_cestino.svg" class="img-fluid dim-icon" alt="">
                    <i class="ti-shopping-cart"></i>
                </div>
            </div>

            <span class="cart_qty_cls">{{ cartItems?.cartItems?.length ?? 0 }}</span>
            @if (cartItems?.cartItems?.length === 0 || !cartItems) {
                <ul class="show-div shopping-cart">
                    <h5>{{ 'CART.EMPTY_CART' | translate }}</h5>
                </ul>
            } @else {
                <ul class="show-div shopping-cart box-shadow">
                    @for (item of cartItems?.cartItems; track item.id; let i = $index) {
                        <li>
                            <div class="d-flex">
                                <div class="align-content-center" style="background-color: #f8f8f8;">
                                    @if (item?.itemStructure?.preview_id_picture?.medium) {
                                        <img ngSrc="{{item?.itemStructure?.preview_id_picture?.medium}}" placeholder
                                             class="img-fluid me-2 ms-2" width="80" height="130"
                                             alt="{{item?.itemStructure?.preview_id_picture?.originalname}}"
                                             loading="lazy"
                                             fetchpriority="auto" style="width: 60px">
                                    }
                                </div>
                                <div class="media-body ms-1 p-1" style="width: 65% !important;">
                                    <a>
                                        <span class="d-flex fw-600 text-dark mb-1">{{ item?.jobName }}</span>
                                    </a>
                                    <span class="mb-0 text-p">({{ item?.estimateCostRequest?.quantity }}
                                        {{ 'CART.QUANTITY_PZ' | translate }}
                                        ) {{ getAmount(item?.estimateCost?.priceNoTax, item?.estimateCost?.tax) |  currency: 'EUR': 'symbol': '1.2-2' | euroFormat }}
                                        {{ 'CART.OF_VAT' | translate }} {{ item?.estimateCost?.tax |  currency: 'EUR': 'symbol': '1.2-2' | euroFormat }}</span>
                                </div>
                            </div>
                            <div class="close-circle">
                                <button type="button" class="close-btn p-0 border-none background-none">
                            <span aria-hidden="true" style="font-size: large; color: gray;"
                                  (click)="deleteItem(item?.id, item?.jobName)">×</span>
                                </button>
                            </div>
                        </li>
                    }
                    <li>
                        <div class="total">
                            <div class="justify-content-between d-flex text-p">{{ 'CART.TOTAL_PRICE' | translate }} :
                                <span
                                        class="text-red-theme fw-600">{{ cartItems?.orderTotal |  currency: 'EUR': 'symbol': '1.2-2' | euroFormat  }} </span></div>
                            <div class="justify-content-between d-flex text-p">{{ 'CART.WHICH_VAT' | translate }} :
                                <span>{{ cartItems?.orderTax |  currency: 'EUR': 'symbol': '1.2-2' | euroFormat}}</span></div>
                        </div>
                    </li>
                    <li>
                        <div class="buttons">
                            <a style="font-size: unset" [routerLink]="['/shop/cart']"
                               class="view-cart"><span>{{ 'CART.GO_CART' | translate }}</span></a>
                            <a (click)="toCheckout()" class="checkout clickable"><span>checkout</span></a>
                        </div>
                    </li>
                </ul>
            }
        </li>
    </ul>
</div>
