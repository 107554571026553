<div class="card-body">
    <div class="absolute-img">
        <img [src]="src ?? 'assets/images/press-up/illustazioni/ill_indirizzi_vuoti.svg'"
             class="img-fluid illustration-h pb-4 pt-0 object-fit-contain" alt="">
    </div>
</div>
@if (title) {
    <h3 class="text-uppercase mb-1">
        {{ title | translate }}
    </h3>
} @else {
    <h3 class="text-uppercase mb-1">
        {{ 'GENERAL.ALMOST_THERE' | translate }}
    </h3>
}
<p class="col-12">
    {{ mex | translate }}
</p>
