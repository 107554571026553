import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {BaseComponent, RegistrationFieldsEnum} from '../../core';
import {TranslateService} from '@ngx-translate/core';
import {PagesFacade} from '../../pages/pages.facade';
import {Subscription, tap, throwError} from 'rxjs';
import {User} from '../../modules/auth/models/User';
import {catchError} from 'rxjs/operators';
import {FeedbackModalComponent} from '../components/feedback-modal/feedback-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModelField} from '../../core/models/model-field';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent extends BaseComponent implements OnInit {
    @Input() class = 'footer-light'; // Default class
    @Input() themeLogo = 'assets/images/icon/logo.png'; // Default Logo
    @Input() newsletter = true; // Default True
    public user: User;
    public registrationFields = RegistrationFieldsEnum;
    public emailToSend: string;
    public condition: boolean;
    public today: Date = new Date();
    public subscriptions: Subscription[] = [];

    constructor(public translateService: TranslateService,
                private pagesFacade: PagesFacade,
                private modalService: NgbModal) {
        super(translateService);
    }

    ngOnInit(): void {
        const sb = this.pagesFacade.getUser$().subscribe(user => {
            this.user = user;
        });
        this.subscriptions.push(sb);
        this.today.getFullYear();
    }

    public sendMail(): void {
        window.open('mailto:assistenza@pressup.it');
    }

    public chatOnSkype(): void {
        window.open('skype:pressup.it?chat', '_blank');
    }

    public sendTextMessageWA(): void {
        window.open('https://wa.me/393470845983?text=', '_blank');
    }

    public submit() {
        const sb = this.pagesFacade.newsletterSubscription(this.emailToSend).pipe(
            tap(_ => this.successModalConfirmSend()),
            catchError((err) => {
                console.error(`error during by signing up to newsletter`, err);
                return throwError(() => err);
            }),
        ).subscribe();
        this.subscriptions.push(sb);
    }

    private successModalConfirmSend(): void {
        const modal = ModelField.buildGenericSuccess();
        const modalRef = this.modalService.open(FeedbackModalComponent, {centered: true});
        modalRef.componentInstance.icon = 'assets/images/modal-feedback/icn_modale_conferma.svg';
        modalRef.componentInstance.title = modal.text;
    }
}
