import {Injectable} from '@angular/core';
import {HttpClient, HttpContext, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, tap, throwError} from 'rxjs';
import {IPaginationParams} from '../interfaces/IPaginationParams';
import {TrustpilotProductReviewResponse} from '../models/trustpilotProductReview.model';
import {IProductSku, IStars, ITrustpilotProductReviewsSummary} from '../models/interfaces/ITrustpilot';
import {catchError} from 'rxjs/operators';
import {IS_TRUST_PILOT_API} from '../enums/trust-pilot-api.token';

@Injectable({
    providedIn: 'root'
})
export class TrustpilotService {

    private readonly trustpilotUrl = 'https://api.trustpilot.com/v1';
    private readonly apiKey = '0guPuo7ZXuXHAsNqI0ziUaaNniw8uurk';
    private readonly businessUnit = '4f094cf8000064000512249c';
    private productSkuList: BehaviorSubject<Array<IProductSku>> = new BehaviorSubject<Array<IProductSku>>([]);
    private apiKeyHeaders: HttpHeaders = new HttpHeaders({apikey: this.apiKey});

    constructor(private http: HttpClient) {
        this.getSkuList();
    }

    public getTrustpilotReviews(paginationParams: IPaginationParams, sku: Array<string>): Observable<TrustpilotProductReviewResponse> {
        const queryParams = `page=${paginationParams?.currentPage}&perPage=${paginationParams?.pageItems}&sku=${sku.toString()}&stars=4,5`;
        return this.http.get<TrustpilotProductReviewResponse>(`${this.trustpilotUrl}/product-reviews/business-units/${this.businessUnit}/reviews?${queryParams}`, {
            headers: this.apiKeyHeaders,
            context: new HttpContext().set(IS_TRUST_PILOT_API, true)
        });
    }

    public getTrustpilotProductReviewsSummary(sku: Array<string>): Observable<ITrustpilotProductReviewsSummary> {
        const queryParams = `&sku=${sku.toString()}`;
        return this.http.get<ITrustpilotProductReviewsSummary>(`${this.trustpilotUrl}/product-reviews/business-units/${this.businessUnit}?${queryParams}`, {
            headers: this.apiKeyHeaders,
            context: new HttpContext().set(IS_TRUST_PILOT_API, true)
        });
    }

    public getTrustpilotStarImageResources(stars: number): Observable<IStars> {
        return this.http.get<IStars>(`${this.trustpilotUrl}/resources/images/stars/${stars}`, {
            headers: this.apiKeyHeaders,
            context: new HttpContext().set(IS_TRUST_PILOT_API, true)
        });
    }

    public skuList(): Observable<Array<IProductSku>> {
        return this.productSkuList.asObservable();
    }

    private getSkuList(): void {
        this.http.get<Array<IProductSku>>('assets/data/sku.json').pipe(
            tap((skuList: Array<IProductSku>) => this.productSkuList.next(skuList)),
            catchError(err => {
                console.error('Error retrieve trustpilot sku list');
                return throwError(() => err);
            })
        ).subscribe();
    }
}
