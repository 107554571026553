import {createActionGroup, props} from '@ngrx/store';
import {StoreFeatureNames} from '../enums/store-feature-names';
import {CartItemsModel} from '../../../../shop/cart/models/cart-items.model';

enum CartAction {
    SET_CART = 'SetCart',
    RESET_CART = 'ResetCart'
}

export const cartAction = createActionGroup({
    source: StoreFeatureNames.CART,
    events: {
        [CartAction.SET_CART]: props<{ data: CartItemsModel }>(),
        [CartAction.RESET_CART]: props<{ data: CartItemsModel }>(),
    }
});
