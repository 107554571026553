import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {forkJoin, Observable, of, Subscription, switchMap, tap, throwError} from 'rxjs';
import {PagesFacade} from '../../../pages/pages.facade';
import {ProcessingOrderCart} from '../../../shop/cart/models/processing-order-cart';
import {catchError, map} from 'rxjs/operators';
import {RedirectModalComponent} from '../../modals/redirect-modal/redirect-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {IPressUpStore} from '../../../core/ngrx/stores/interfaces/IPressUpStore';
import {cartFeatures} from '../../../core/ngrx/stores/features/cart-features';
import {CartItemsModel} from '../../../shop/cart/models/cart-items.model';
import {cloneDeep} from 'lodash';
import {cartAction} from '../../../core/ngrx/stores/action/cart.action';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../../core';
import {MessageService} from 'primeng/api';
import {AutoUnsubscribe} from 'ngx-ap-autounsubscribe';

@AutoUnsubscribe({arrayName: 'subscriptions'})
@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends BaseComponent implements OnInit {

    public products: ProcessingOrderCart[] = [];
    public search = false;
    public subscriptions: Array<Subscription> = [];
    public loading = false;
    public cartItems: CartItemsModel;

    constructor(public pagesFacade: PagesFacade,
                public translateService: TranslateService,
                private messageService: MessageService,
                private modalService: NgbModal,
                private router: Router,
                private store: Store<IPressUpStore>,
                private cdr: ChangeDetectorRef) {
        super(translateService);
    }

    ngOnInit(): void {
        this.getCartItem();
    }

    private getCartItem(): void {
        const sb = this.store.select(cartFeatures.selectCartState).pipe(
            switchMap((data: { data: CartItemsModel }) => {
                if (data?.data?.cartItems?.length > 0) {
                    this.loading = true;
                    return this.setItemStructure(cloneDeep(data.data));
                } else {
                    return of(data?.data);
                }
            }),
            tap((updatedCartItems) => {
                this.cartItems = updatedCartItems;
                this.cartItems?.cartItems?.forEach(item => item.expanded = false);
                this.loading = false;
                this.cdr.detectChanges();
            }),
            catchError(error => {
                console.error('Error getting cart data => ', error);
                const fieldName = this.translateService.instant('CART.TITLE');
                this.check500StatusCode(error?.status, fieldName, this.messageService);
                this.loading = false;
                return throwError(() => error);
            })
        ).subscribe();
        this.subscriptions.push(sb);
    }

    public toCheckout() {
        const sb = this.pagesFacade.getUser$().pipe(
            tap(user => {
                if (user && !user?.userinfo) {
                    const modalRef = this.modalService.open(RedirectModalComponent, {
                        centered: true,
                        backdrop: 'static',
                        windowClass: 'commandsModal'
                    });
                    modalRef.componentInstance.icon = 'assets/images/press-up/icone-cerchio/icn_modale_user.svg';
                    modalRef.componentInstance.subTitle = 'MODAL.COMPLETE_ACCOUNT';
                    modalRef.componentInstance.visibleCancelBtn = true;
                    this.successModal(modalRef);
                } else {
                    if (user) {
                        this.router.navigate(['/shop/cart/shipping-type']);
                    }
                }
            })).subscribe();
        this.subscriptions.push(sb);
    }

    public getAmount(priceNoTax: number, tax: number): number {
        return priceNoTax + tax;
    }

    public deleteItem(i: number, jobName: string): void {
        this.products.splice(i, 1);
        this.removeCartItem(i, jobName);
    }

    private successModal(modalRef: NgbModalRef) {
        const sb = modalRef.closed.pipe(tap(() => this.router.navigateByUrl('/profile/account-data'))).subscribe();
        this.subscriptions.push(sb);
    }

    private removeCartItem(idItem: number, jobName: string): void {
        this.loading = true;
        let customerUser: string;
        const sb = this.pagesFacade.getUser$().pipe(
            switchMap((response) => {
                customerUser = response?.username;
                return this.pagesFacade.removeCartItem(idItem, customerUser);
            }),
            switchMap(_ => this.pagesFacade.getCartStatus(customerUser)),
            tap(response => {
                this.store.dispatch(cartAction.setCart({data: response}));
                this.loading = false;
            }),
            catchError(error => {
                console.error('Remove cart item error', error);
                this.loading = false;
                const fieldName = this.translateService.instant('PAGES.STATIC_PAGES.ESTIMATE.FORM_ESTIMATE_REQUEST.PRODUCT');
                this.deletingItemError(error?.status, fieldName, this.messageService, jobName);
                return throwError(() => error);
            })
        ).subscribe();
        this.subscriptions.push(sb);
    }

    private setItemStructure(cartItems: CartItemsModel): Observable<CartItemsModel> {
        const requests$ = cartItems?.cartItems?.map(item =>
            this.pagesFacade.getItemStructureById(item?.estimateCostRequest?.externalReference).pipe(
                map(response => ({ ...item, itemStructure: response }))
            )
        );
        return forkJoin(requests$).pipe(map(updatedItems => ({ ...cartItems, cartItems: updatedItems })));
    }
}
