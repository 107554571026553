import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {PagesFacade} from '../../../pages/pages.facade';
import {Subscription, switchMap, tap, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {
    IProductSku,
    IStars,
    ITrustpilotProductReviewsSummary,
    ITrustpilotReview
} from '../../models/interfaces/ITrustpilot';
import {BaseComponent} from '../../../core';
import {TranslateService} from '@ngx-translate/core';
import {IPaginationParams} from '../../interfaces/IPaginationParams';
import {TrustpilotProductReviewResponse} from '../../models/trustpilotProductReview.model';
import {AutoUnsubscribe} from 'ngx-ap-autounsubscribe';
import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it-IT');

@AutoUnsubscribe({arrayName: 'subscriptions'})
@Component({
    selector: 'app-trustbox-product-reviews',
    templateUrl: './trustbox-product-reviews.component.html',
    styleUrls: ['./trustbox-product-reviews.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrustboxProductReviewsComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() productSku: string;
    @Input() categoriesSku: string[];
    @Input() isHomeSection: boolean;
    public reviews: Array<ITrustpilotReview> = [];
    public productReviewsSummary: ITrustpilotProductReviewsSummary;
    public startsAverage: string;
    public startsImg: string;
    public subscriptions: Array<Subscription> = [];

    private sku: Array<string> = [];
    private allSku: Array<IProductSku> = [];

    constructor(private pageFacade: PagesFacade,
                public translate: TranslateService,
                private  cdr: ChangeDetectorRef) {
        super(translate);
    }

    ngOnInit(): void {
        this.currentPage = 1;
        this.pageItems = 10;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.currentPage = 1;
        this.pageItems = 10;
        this.productSku = changes?.productSku?.currentValue ?? this.productSku;
        this.categoriesSku = changes?.categoriesSku?.currentValue ?? this.categoriesSku;
        this.isHomeSection = changes?.isHomeSection?.currentValue ?? this.isHomeSection;
        if (this.isHomeSection) {
            this.getSkuList();
        }
        if (this.categoriesSku) {
            this.getSkuByCategory();
        }
        if (this.productSku) {
            this.getSkuByProduct();
        }
    }

    public goToNextPage(): void {
        this.currentPage++;
        this.getTrustpilotReviews();
    }

    // Private methods
    private getTrustpilotProductReviewsSummary(): void {
        const sub = this.pageFacade.getTrustpilotProductReviewsSummary(this.sku).pipe(
            switchMap((productReviewsSummary: ITrustpilotProductReviewsSummary) => {
                this.productReviewsSummary = productReviewsSummary;
                const starAverage = this.calcStarsAverage(productReviewsSummary);
                this.startsAverage = starAverage;
                const starRounded = this.roundToHalf(parseFloat(starAverage));
                return this.pageFacade.getTrustpilotStarImageResources(starRounded);
            }),
            tap((response: IStars) => {
                this.startsImg = response?.starSvg?.url;
                this.cdr.detectChanges();
            }),
            catchError(err => {
                console.error('Error retrieve trustpilot data');
                return throwError(() => err);
            })
        ).subscribe();
        this.subscriptions.push(sub);
    }

    private getTrustpilotReviews(): void {
        const paginationParams: IPaginationParams = {
            currentPage: this.currentPage,
            pageItems: this.pageItems
        };
        const sub = this.pageFacade.getTrustpilotReviews(paginationParams, this.sku).pipe(
            tap((data: TrustpilotProductReviewResponse) => {
                const mappedReviews = this.getStarsImage(data?.productReviews ?? []);
                this.reviews = this.reviews.concat(mappedReviews);
                this.cdr.detectChanges();
            }),
            catchError(err => {
                console.error('Error retrieve trustpilot reviews data');
                return throwError(() => err);
            })
        ).subscribe();
        this.subscriptions.push(sub);
    }

    private getStarsImage(reviews: Array<ITrustpilotReview>): Array<ITrustpilotReview> {
        return reviews.map(review => {
            review.starImage$ = this.pageFacade.getTrustpilotStarImageResources(review?.stars).pipe(
                map((response: IStars) => response?.starSvg?.url)
            );
            return review;
        });
    }

    private calcStarsAverage(productReviewsSummary: ITrustpilotProductReviewsSummary): string {
        const average = ((productReviewsSummary.numberOfReviews.fiveStars * 5) + (productReviewsSummary.numberOfReviews.fourStars * 4)) /
            (productReviewsSummary.numberOfReviews.fourStars + productReviewsSummary.numberOfReviews.fiveStars);
        return average.toFixed(1);
    }

    private roundToHalf(num: number): number {
        return Math.round(num * 2) / 2;
    }

    private getSkuList(): void {
        const sub = this.pageFacade.skuList().pipe(
            tap(skuList => {
                if (skuList?.length > 0) {
                    this.allSku = skuList;
                    this.sku = this.allSku.map(sku => sku.SKU);
                    this.getTrustpilotReviews();
                    this.getTrustpilotProductReviewsSummary();
                }
            }),
        ).subscribe();
        this.subscriptions.push(sub);
    }

    private getSkuByProduct(): void {
        this.sku.push(this.productSku);
        this.getTrustpilotReviews();
        this.getTrustpilotProductReviewsSummary();
    }

    private getSkuByCategory(): void {
        this.sku = this.categoriesSku;
        this.getTrustpilotReviews();
        this.getTrustpilotProductReviewsSummary();
    }
}
