import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ILabelValueRoute} from '../../modules/profile/interfaces/TabSelectedCode';
import {IPredictionResponse} from '../models/interfaces/IPredictionResponse';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {getPhotonQuerySearch, IPhotonAdditionalFilter} from '../models/interfaces/IPhotonAdditionalFilter';
import {UploadFiles} from '../models/upload-files.model';
import {FileUploaded} from '../models/file-uploaded.model';
import {ContactUsModel} from '../models/contact-us.model';
import {CartItem} from '../../shop/cart/models/cart-items.model';
import {GenericResponse} from '../../modules/auth/models/generic.response';
import {TryForOneEuroModel} from '../models/try-for-one-euro.model';
import {Meta, Title} from '@angular/platform-browser';
import {SeoModel} from '../models/seo.model';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private readonly ENDPOINT_V1: string = `${environment.addressPressupBackofficeAPI}${environment.api_v1}`;
    private readonly ENDPOINT_AZURE: string = `${environment.addressPressupAPIDEM}${environment.api_v1}`;
    private _tabProfileSelected$ = new BehaviorSubject<ILabelValueRoute>(undefined);
    private _searchTerms$ = new BehaviorSubject<string>(undefined);

    constructor(private http: HttpClient,
                private metaService: Meta,
                private titleService: Title,
                private router: Router) {
    }

    public getTabProfileSelected$(): Observable<ILabelValueRoute> {
        return this._tabProfileSelected$.asObservable();
    }

    public setTabProfileSelected$(value: ILabelValueRoute): void {
        return this._tabProfileSelected$.next(value);
    }

    public getSearchTerms$(): Observable<string> {
        return this._searchTerms$.asObservable();
    }

    public setSearchTerms$(value: string): void {
        return this._searchTerms$.next(value);
    }

    public getInitialPhotonValues(): Observable<IPredictionResponse> {
        return this.http.get<IPredictionResponse>(`${environment.photonApi}?q=`);
    }

    public getPredictionByFilters(searchParams: string | undefined, additionalQueryParams: string, otherFilters?: IPhotonAdditionalFilter): Observable<IPredictionResponse> {
        return this.http.get<IPredictionResponse>(`${environment.photonApi}?q=${searchParams}${getPhotonQuerySearch(otherFilters) ?? ''}${additionalQueryParams}`);
    }

    public getByDynamicUrl<T>(url: string): Observable<T> {
        return this.http.get<T>(url);
    }

    public uploadFile(files: UploadFiles): Observable<FileUploaded> {
        const formData: FormData = new FormData();
        formData.append('files', files?.files[0]);
        return this.http.post<FileUploaded>(`${environment.addressPressupBackoffice}/document`, formData);
    }

    public sendMailDesignYourIdea(contact: ContactUsModel): Observable<any> {
        return this.http.post<any>(`${this.ENDPOINT_V1}/utility/email`, contact);
    }

    public getCaptcha(): Observable<any> {
        return this.http.get<any>(`${this.ENDPOINT_V1}/utility/captcha`, {observe: 'response'});
    }

    public getCartItem(guid: string, id: number): Observable<CartItem> {
        return this.http.get<CartItem>(`${this.ENDPOINT_AZURE}/ShoppingCart/GetCartItem?guid=${guid}&id=${id}`);
    }

    public setNotes(cartItemId: number, note: string, guid: string): Observable<GenericResponse> {
        const param = new HttpParams();
        return this.http.patch<GenericResponse>(`${this.ENDPOINT_AZURE}/ShoppingCart/SetNotes?cartitemid=${cartItemId}&note=${note}&guid=${guid}`, param);
    }

    public setDesignService(cartItemId: number, isChecked: boolean, guid: string): Observable<GenericResponse> {
        const param = new HttpParams();
        return this.http.patch<GenericResponse>(`${this.ENDPOINT_AZURE}/ShoppingCart/SetDesignService?cartitemid=${cartItemId}&ischecked=${isChecked}&guid=${guid}`, param);
    }

    public setDiscount(discountCode: string, guid: string): Observable<GenericResponse> {
        const param = new HttpParams();
        return this.http.patch<GenericResponse>(`${this.ENDPOINT_AZURE}/ShoppingCart/ApplyDiscount?discountCode==${discountCode}&guid=${guid}`, param);
    }

    public getAllTryOneEuroProducts(): Observable<Array<TryForOneEuroModel>> {
        return this.http.get<Array<TryForOneEuroModel>>(`${this.ENDPOINT_V1}/tryfor`);
    }

    public getByIndex(id: string): Observable<TryForOneEuroModel> {
        return this.http.get<TryForOneEuroModel>(`${this.ENDPOINT_V1}/tryfor/${id}`);
    }

    public setOrderPaid(orderId: number, amount: number): Observable<GenericResponse> {
        const param = new HttpParams();
        return this.http.patch<GenericResponse>(`${this.ENDPOINT_AZURE}/Order/SetOrderPaid?orderid=${orderId}&amount=${amount}`, param);
    }

    // --- SEO MANAGEMENT AREA ---
    public updateMetaTags(seo?: SeoModel, lang?: string): void {
        const completeUrlPage = environment.domain + this.router?.url?.split('/')[1];
        const title = lang === 'en' ? seo?.seo_title_en : seo?.seo_title_it;
        const description = lang === 'en' ? seo?.seo_description_en : seo?.seo_description_it;
        const keywords = this.joinKeywords(seo?.seo_tags);
        this.titleService.setTitle(title);
        this.metaService.updateTag({ name: 'keywords', content: keywords });
        this.metaService.updateTag({ name: 'description', content: description });
        this.metaService.updateTag({ name: 'subject', content: title });
        this.metaService.updateTag({ name: 'url', content: completeUrlPage });
        this.metaService.updateTag({ name: 'identifier-URL', content: completeUrlPage });
        this.metaService.updateTag({ name: 'directory', content: completeUrlPage });
        this.metaService.updateTag({ name: 'pagename', content: title });
        this.metaService.updateTag({ name: 'application-name', content: 'PressUp' });
        this.metaService.updateTag({ name: 'og:title', content: title });
        this.metaService.updateTag({ name: 'og:type', content: title });
        this.metaService.updateTag({ name: 'og:url', content: completeUrlPage });
        this.metaService.updateTag({ name: 'og:image', content: `${environment.domain}assets/images/press-up/loghi/logo_pressup_rosso.svg` });
        this.metaService.updateTag({ name: 'og:site_name', content: 'PressUp' });
        this.metaService.updateTag({ name: 'og:description', content: description });
        this.setCanonicalTags(completeUrlPage);
    }


    private setCanonicalTags(completeUrlPage: string): void {
        const canonicalTag = this.metaService.getTag('rel="canonical"');
        if (canonicalTag) {
            this.metaService.updateTag({rel: 'canonical', href: completeUrlPage});
        } else {
            this.metaService.addTag({rel: 'canonical', href: completeUrlPage});
        }
    }

    private joinKeywords(keywords: string[]): string {
        return keywords?.join(", ");
    }
}
